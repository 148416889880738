import { Link, graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import Hamburger from "../hamburger"
import Img from "gatsby-image"

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to right, #d61a3c, #c40233, #b2022f);
  padding: 20px;
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 1000;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`

const LinkList = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 25px;
  a {
    color: #fff;
    text-decoration: none;
    font-family: "Poppins";
  }
  @media only screen and (max-width: 786px) {
    display: none;
  }
`

const Logo = styled.img`
  height: 100px;
  position: absolute;
  top: 20;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: all 1s ease;
  :hover {
    height: 300px;
  }
  @media only screen and (max-width: 786px) {
    height: 100px;
  }
`

export default () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      file(relativePath: { eq: "favicon.png" }) {
        childImageSharp {
          fixed(height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Container>
      <div>
        <Link to="/">
          <Img
            fixed={data.file.childImageSharp.fixed}
            alt="ProllyDrunk"
            style={{
              position: "absolute",
              top: 10,
              zIndex:100000,
              boxShadow:
                "0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)",
            }}
          />
        </Link>
      </div>
      <div>
        <LinkList>
          <Link to="/blogs">Blog</Link>
          <Link to="/about">About</Link>
          <Link to="/contact">Contact</Link>
        </LinkList>
        <Hamburger />
      </div>
    </Container>
  )
}
