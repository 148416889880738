import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Icon = styled.div`
  width: 40px;
  height: 35px;
  position: relative;
  transform: rotate(0deg);
  z-index: 10000;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 5px;
    width: 50%;
    background: #fff;
    opacity: 1;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  span:nth-child(even) {
    left: 45%;
    border-radius: 0 9px 9px 0;
  }

  span:nth-child(odd) {
    left: 0px;
    border-radius: 9px 0 0 9px;
  }

  span:nth-child(1),
  span:nth-child(2) {
    top: 0px;
  }

  span:nth-child(3),
  span:nth-child(4) {
    top: 10px;
  }

  span:nth-child(5),
  span:nth-child(6) {
    top: 20px;
  }

  &.open span:nth-child(1),
  &.open span:nth-child(6) {
    transform: rotate(45deg);
  }

  &.open span:nth-child(2),
  &.open span:nth-child(5) {
    transform: rotate(-45deg);
  }

  &.open span:nth-child(1) {
    left: 5px;
    top: 10px;
  }

  &.open span:nth-child(2) {
    left: calc(50% - 5px);
    top: 10px;
  }

  &.open span:nth-child(3) {
    left: -50%;
    opacity: 0;
  }

  &.open span:nth-child(4) {
    left: 100%;
    opacity: 0;
  }

  &.open span:nth-child(5) {
    left: 5px;
    top: 20px;
  }

  &.open span:nth-child(6) {
    left: calc(50% - 5px);
    top: 20px;
  }
`

const Container = styled.div`
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
  }
`
const Spacey = styled.hr`
  width: 100%;
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0)
  );
`

const List = styled.div`
  position: fixed;
  height: ${props => (props.hide ? "0vh" : "100vh")};
  background: ${props => props.theme.primaryColor};
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  transition: all 0.5s ease;
`

const Child = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  grid-gap: 10px;
  text-align: center;
  a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    font-size: 20px;
  }
`

export default ({ renderChild }) => {
  const [isOpen, toggle] = React.useState(false)

  return (
    <Container>
      <Icon className={isOpen ? "open" : ""} onClick={() => toggle(!isOpen)}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </Icon>
      <List hide={!isOpen}>
        <Child>
          <Link to="/blogs">Blog</Link>
          <Spacey />
          <Link to="/about">About</Link>
          <Spacey />
          <Link to="/contact">Contact</Link>
        </Child>
      </List>
    </Container>
  )
}
