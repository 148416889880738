import React from "react";
import { ThemeProvider } from "styled-components";
import Header from "../../components/header";
import Footer from '../../components/footer';
import theme from "../../theme"
import styled from "styled-components";
import './default.css';

const Container = styled.div`
  margin: 0;
  background: #e3e3e3;
`;

const Content = styled.div`
  margin: auto;
  min-height: 400px;
`;

const Default = props => {
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Header />
        <Content>{props.children}</Content>
        <Footer />
      </Container>
    </ThemeProvider>
  )
}

export default Default
