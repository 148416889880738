import { Link, graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import Instagram from "../../images/icons/instagram.svg"
import Github from "../../images/icons/github.svg"
import Linkedin from "../../images/icons/linkedin.svg"

const Container = styled.div`
  background: #353839;
  padding: 20px;
  min-height: 70px;
  text-align: center;
`

const Copy = styled.div`
  color: #fff;
  font-size: 12px;
  span {
    margin: 0px 5px;
    color: ${props => props.theme.primaryColor};
  }
`

const Icon = styled.img`
  height: 40px;
  margin: 0px 10px;
  opacity: 0.75;
  :hover {
    opacity: 1;
  }
  @media only screen and (max-width: 786px) {
    height: 25px;
  }
`

const SocialLinks = styled.div`
  margin: 0 auto;
  a {
    color: grey;
    font-size: 50px;
    text-align: center;
    transition: all 0.5s ease;
    :hover {
      color: ${props => props.theme.primaryColor};
    }
    :active {
      color: ${props => props.theme.primaryColor};
    }
  }
  @media only screen and (max-width: 786px) {
    a {
      font-size: 35px;
    }
  }
`

export default () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
          social {
            instagram
            linkedin
          }
        }
      }
      file(relativePath: { eq: "logo-halo.png" }) {
        childImageSharp {
          fixed(width: 150, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Img fixed={data.file.childImageSharp.fixed} alt='ProllyDrunk'/>
      {data.site.siteMetadata.social && (
        <SocialLinks>
          <a href={data.site.siteMetadata.social.linkedin}>
            <Icon src={Linkedin} alt={data.site.siteMetadata.social.linkedin} />
          </a>
          <a href={data.site.siteMetadata.social.instagram}>
            <Icon
              src={Instagram}
              alt={data.site.siteMetadata.social.instagram}
            />
          </a>
          <a href={data.site.siteMetadata.social.github}>
            <Icon src={Github} alt={data.site.siteMetadata.social.github} />
          </a>
        </SocialLinks>
      )}
      <Copy>
        project by
        <span>{data.site.siteMetadata.author}.</span>
      </Copy>
    </Container>
  )
}
